import React from "react";
import { Bar } from "@ant-design/plots";

const BarChart = ({ dashboardGraph }) => {

  const processData = () => {
    if (!dashboardGraph) {
      return [];
    }

    return dashboardGraph.flatMap((employee) => {
      const totalProjectsWorked = employee.totalProjectsWorked || 0;
      const totalActivityWorked = employee.totalActivityWorked || 0;
      const totalWorked = employee.totalWorkedCount || 0;

      return [
        {
          employeeName: employee.employeeName,
          value: totalProjectsWorked,
          type: "Projects Hours Worked",
          totalWorked: totalWorked, 
        },
        {
          employeeName: employee.employeeName,
          value: totalActivityWorked,
          type: "Activity Hours Worked",
          totalWorked: totalWorked,
        },
      ];
    });
  };

  const maxYaxis = () => {
    const yaxisCount = {
      domainMax: 5,
      tickCount: 5,
    };

    if (!dashboardGraph) return 5;

    const maxCount = Math.max(
      ...dashboardGraph.map(
        (employee) => employee.totalWorkedCount
      )
    );
    
    if (maxCount + 1 <= 5) {
      yaxisCount.domainMax = maxCount + 1;
      yaxisCount.tickCount = maxCount + 1;
    } else {
      const tempMax = Math.floor(maxCount / 5);
      yaxisCount.domainMax = maxCount + tempMax;
    }

    return yaxisCount;
  };

  const Handleratio = () => {
    const ratio = 25 / dashboardGraph?.length;
    if (ratio >= 1) {
      return null;
    } else {
      return ratio;
    }
  };

  const config = {
    data: processData(),
    xField: "employeeName",
    yField: "value",
    colorField: "type",
    stack: true,
    color: ["#6394f9", "#62daab"],
    barStyle: {
      radius: [4, 4, 0, 0],
    },
    axis: {
      x: {
        title: "Employee Name",
        labelFontSize: 12,
        label: {
          autoHide: false,
          formatter: (val) => val.split(" ")[0],
        },
      },
      y: {
        title: "Hours Worked",
        line: {
          style: {
            stroke: "#000",
          },
        },
      },
    },
    scrollbar: {
      x: {
        ratio: Handleratio(),
      },
    },
    scale: {
      y: maxYaxis(),
    },
    legend: {
      position: "top-left",
    },
  };
  
  return <Bar {...config} />;
};

export default BarChart;
